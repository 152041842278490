import jump from 'jump.js';

((() => {
  const button = document.getElementById('StoryScroll');
  const header = document.getElementById('Header');
  if (button) {
    const target = button.getAttribute('data-target');
    if (target) {
      button.addEventListener('click', () => {
        jump(target, {
          offset: header ? header.clientHeight * -1 : -90,
        });
      }, false);
    }
  }
})());

((() => {
  const button = document.getElementById('StoryScroll2');
  const header = document.getElementById('Header');
  if (button) {
    const target = button.getAttribute('data-target');
    if (target) {
      button.addEventListener('click', () => {
        jump(target, {
          offset: header ? header.clientHeight * -1 : -90,
        });
      }, false);
    }
  }
})());

((() => {
  const button = document.getElementById('StoryScroll3');
  const header = document.getElementById('Header');
  if (button) {
    const target = button.getAttribute('data-target');
    if (target) {
      button.addEventListener('click', () => {
        jump(target, {
          offset: header ? header.clientHeight * -1 : -90,
        });
      }, false);
    }
  }
})());
