import jump from 'jump.js';

((() => {
  const anchor = document.querySelector('.anchor');
  const header = document.getElementById('Header');

  if (anchor) {
    const links = anchor.querySelectorAll('a');

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const href = link.getAttribute('href');
      link.addEventListener('click', (event) => {
        event.preventDefault();
        if (document.querySelector(href)) jump(href, {offset: header ? header.clientHeight * -1 : -90});
      }, false);
    }
  } else {
    return;
  }
})());