((($) => {
  const items = $('.stores-item');
  items.each((index, item) => {
    const galleryItems = $(item).find('.stores-gallery-link');
    const carousel = $(item).find('.carousel');
    galleryItems.each((index, galleryItem) => {
      $(galleryItem).on('click', function (event) {
        event.preventDefault();
        const number = $(this).attr('data-slide-to');
        carousel.carousel(parseInt(number));
      });
    })
  });
})(window.jQuery));