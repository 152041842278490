((() => {
  const toggler = document.getElementById('Toggler');
  const nav = document.getElementById('Gnav');
  const toggleClass = 'is-open';

  if (toggler && nav) {
    toggler.addEventListener('click', () => {
      toggler.classList.toggle(toggleClass);

      if (toggler.classList.contains(toggleClass)) {
        nav.classList.add(toggleClass);
      } else {
        nav.classList.remove(toggleClass);
      }
    }, false);
  }
})());