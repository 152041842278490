import Footer from './component/Footer';

require('bootstrap');

window.addEventListener('DOMContentLoaded', () => {
  require('assets/js/component/Brand');
  require('assets/js/component/Hero').default();
  require('assets/js/component/PageTop');
  require('assets/js/component/Toggler');
  require('assets/js/component/Gnav');
  require('assets/js/component/ScrollAnim');
  require('assets/js/component/StoresGallery');
  require('assets/js//component/Anchor');
  require('assets/js/component/Story');
  require('assets/js/component/Banner');

  Footer.setFooterIcon();
}, false);